<template>
  <section class="ecommerce_part1 mt-4">
    <div class="container">
      <div class="deals_heading row">
        <div class="heading_left col-md-11">
          <h2 class="title"><strong>ECOMMERCE BUYERS ZONE</strong></h2>
        </div>
        <div class="col-md-1 mt-4">
          <a class="link-success" id="view_all"> View All</a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 col-sm-3" v-for="city in top_cities.slice(0,6)" :key="city.id">
          <div class="product-grid">
            <div class="product-content">
              <h3 class="title title_product">
                <a href="#">{{city.name}}</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {TOP_CITY_LIST} from "@/core/services/store/module/city";
import { mapGetters } from 'vuex';
export default {
  name: "BuyerZone",
  data() {
    return {
      // products: [],
    };
  },
  async created() {
    this.$store.dispatch(TOP_CITY_LIST);
    // this.products.push({
    //   image1: (await import("../../assets/image/1.jpg")).default,
    //   image2: (await import("../../assets/image/2.jpg")).default,
    // });
  },
  computed:{
    ...mapGetters(['top_cities']),
  }
};
</script>