<template>
  <section id="deals">
    <div class="container best_deals">
      <div class="deals_heading row">
        <div class="heading_left col-md-11" v-if="flash_dealList.length>0">
          <h2 class="title">Best Deals of the week!</h2>
          <div class="heading_count" v-if="time_left!=''">
            <h3>{{parseInt(time_left.hours)}}</h3>
            &nbsp;
            <!-- <h3>4</h3>
            &nbsp; -->
            <p>:</p>
            &nbsp;
            <h3>{{parseInt(time_left.minutes)}}</h3>
            &nbsp;
            <!-- <h3>8</h3>
            &nbsp; -->
            <p>:</p>
            &nbsp;
            <h3>{{parseInt(time_left.seconds)}}</h3>
            <!-- &nbsp;
            <h3>3</h3> -->
          </div>
        </div>
        <div class="col-md-1 mt-4">
          <router-link to="view-all" class="">View All</router-link>
        </div>
      </div>
      <div class="row">
        <!-- <div class="col-lg-2">
                    <div class="deals_img">
                        <img class="img-fluid w-100" src="images/cap.jpg" alt="">
                    </div>
                </div> -->
        <!--Start-->
        
        <div class="container">
          <div class="row" v-if="flash_dealList.length>0">
            <div class="col-md-2 col-sm-3" v-for="(product,index) in flash_dealList[0].flash_deal_products" :key="index">
              <div class="product-grid">
                <div class="product-image">
                  <router-link :to="{name: 'single.product', params: {slug:product.product.slug}}" class="image">
                    <img class="pic-1" :src="'https://api.e-littlemart.com/'+product.product.thumbnail_img" />
                    <img class="pic-2" :src="'https://api.e-littlemart.com/'+product.product.thumbnail_img" />
                  </router-link>
                  <span class="product-sale-label" v-if="product.discount_type=='flat'">{{product.discount}}</span>
                  <span class="product-sale-label" v-else>{{product.discount}}</span>
                </div>
                <div class="product-content">
                  <h3 class="tile title_product">
                    <a href="#">{{product.product.name}}</a>
                  </h3>
                  <div class="progress">
                    <div
                      class="progress-bar bg-success"
                      role="progressbar"
                      style="width: 60%"
                      aria-valuenow="30"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between mt-2">
                    <div>
                      <span>Sold : {{product.product.num_of_sale}}</span>
                    </div>
                    <div>
                      <span class="text-primary">Available : 10</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>

        <!--end-->
      </div>
    </div>
  </section>
  <!-- <div>
    <div v-for="(flash, k) in flash_dealList" class="weekly-market-wrapper" :key="k">
      <div class="container">
        <div class="row">
          <h2 class="page-title"><a href="javascript:void(0);">{{ flash.title }}</a></h2>
        </div>
        <div class="row">
          <div class="col-sm-2 mb-2">
            <div class="custom-feature-grid weekly-deals rounded" style="min-height: 100%;">
              <p>{{ $t("message.deal.deals_end_in") }}</p>
              <Timer
                  :starttime="new Date().getTime()"
                  :endtime="flash.end_date"
                  trans='{"status": {}}'></Timer>
              <router-link :to="'flash-deal/'+flash.slug" class="btn-more">{{ $t("message.deal.view_more") }}</router-link>
            </div>
          </div>
          <div v-for="(flash_deal, i) in flash.flash_deal_products.slice(0,4)" class="col col-md-2 mb-2" :key="i">
            <div class="card-wrapper h-100">
                <div class="card" style="cursor: pointer"  @click="productPage(flash_deal.product.slug)">
                  <img class=" img-fluid zoom-in" style="padding: 5px 5px 5px 5px" v-lazy="showImage(flash_deal.product.thumbnail_img)"
                       alt="Card image cap">
                  <div class="card-body">
                    <p class="card-text price">{{ $t("message.deal.product_price") }} {{ productDiscountPrice(flash_deal.product,flash_deal) }}</p>
                    <p class="discount"><span>{{ $t("message.deal.product_price") }} {{ productPrice(flash_deal.product) }}</span> <span
                        class="badge badge-pill badge-warning">-{{ flash_deal.discount }} {{flash_deal.discount_type === 'percent'?'%':flash_deal.product.currency?flash_deal.product.currency.symbol:'' }}</span></p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> -->
</template>

<script>
import { mapGetters } from "vuex";
import { FLASH_DEALS_LIST } from "@/core/services/store/module/flash_deals";
import { api_base_url } from "@/core/config/app";
import Timer from "@/components/helper/Timer";
import moment from 'moment';
export default {
  name: "Deal",
  data() {
    return {
      product_image1: "",
      product_image2: "",
      time_left: ""
    };
  },
  
  methods: {
    showImage(e) {
      return api_base_url + e;
    },
    time_left_in_hms(){
      let end_date=this.$store.getters.flash_dealList[0].end_date
      let current_time=moment()
      let time_left=moment(end_date).diff(current_time,'seconds')
      let hours_left=time_left/(60*60)
      let minutes_left=(time_left % (60*60))/60
      let seconds_left=(time_left % (60*60))%60
      console.log('time left',moment().to(end_date))
      this.time_left={hours:hours_left,minutes:minutes_left,seconds:seconds_left}
    },
    /*
     * method for single product page redirection
     * */
    productPage(slug) {
      this.$router.push({ name: "single.product", params: { slug: slug } });
    },
    /*
     * method for product price rand calculate
     * */
    productPrice(product) {
      if (product.priceType == 0) {
        return product.unit_price;
      } else if (product.priceType == 1 && product.product_stock.length > 0) {
        let max = Math.max(...product.product_stock.map((d) => d.price));
        let min = Math.min(...product.product_stock.map((d) => d.price));
        return min + "-" + max;
      } else if (product.priceType == 2 && product.price_variation.length > 0) {
        let max = Math.max(...product.price_variation.map((d) => d.off_price));
        let min = Math.min(...product.price_variation.map((d) => d.off_price));
        return min + "-" + max;
      }
    },
    /*
     * method for product after discount price rand calculate
     * */
    productDiscountPrice(product, flash_deal) {
      if (product.priceType == 0) {
        if (flash_deal.discount_type === "percent") {
          return (
            product.unit_price -
            product.unit_price * (flash_deal.discount / 100)
          );
        } else return product.unit_price - flash_deal.discount;
      } else if (product.priceType == 1 && product.product_stock.length > 0) {
        let max = Math.max(...product.product_stock.map((d) => d.price));
        let min = Math.min(...product.product_stock.map((d) => d.price));
        if (flash_deal.discount_type === "percent") {
          min = min - min * (flash_deal.discount / 100);
          max = max - max * (flash_deal.discount / 100);
        } else {
          min = min - flash_deal.discount;
          max = max - flash_deal.discount;
        }
        return min + "-" + max;
      } else if (product.priceType == 2 && product.price_variation.length > 0) {
        let max = Math.max(...product.price_variation.map((d) => d.off_price));
        let min = Math.min(...product.price_variation.map((d) => d.off_price));
        if (flash_deal.discount_type === "percent") {
          min = min - min * (flash_deal.discount / 100);
          max = max - max * (flash_deal.discount / 100);
        } else {
          min = min - flash_deal.discount;
          max = max - flash_deal.discount;
        }
        return min + "-" + max;
      }
    },
  },
  created() {
    this.flash_dealList.length==0 ? this.$store.dispatch(FLASH_DEALS_LIST) : "";
    setInterval(()=>{
      this.time_left_in_hms()
    },[1000]);
  },
  mounted(){
    console.log('flash deals',this.flash_dealList)
  },
  computed: {
    ...mapGetters(["flash_dealList"]),
    
  },
  components: { Timer },
};
</script>

<style scoped>
</style>
