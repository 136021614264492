<template>
  <div>
    <section id="deals">
      <div class="container best_deals">
        <div class="deals_heading row">
          <div class="heading_left col-md-11">
            <h2 class="title">BRAND WE LOVE</h2>
          </div>
          <div class="col-md-1 mt-4">
            <a class="link-success" id="view_all"> View All</a>
          </div>
        </div>
        <div class="row">
          <div class="container">
            <div class="row">
              <div
                class="col-md-2 col-sm-3"
                v-for="(brand,idx) in brands"
                :key="idx"
              >
                <div class="product-grid">
                  <div class="product-image">
                    <a href="#" class="image">
                      <!-- <img class="pic-1" v-lazy="showImage(brand.logo)" /> -->
                      <img class="pic-1" :src="brand.logo" />
                      <!-- <img class="pic-2" v-lazy="showImage(brand.logo)" /> -->
                    </a>
                  </div>
                  <div class="product-content"></div>
                </div>
              </div>
            </div>
          </div>

          <!--end-->
        </div>
      </div>
    </section>
    <section class="join">
      <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <h1 class="display-4 text-light text-center bold_big">
            Ready To Join Our MarketPlace!
          </h1>
          <p class="lead text-center text-light text-blod">
            Over 2500 clients trust United IT Solution.
          </p>
          <center>
            <a href="" class="btn btn-danger d-flex-4">Join Us Today</a>
          </center>
        </div>
      </div>
    </section>
  </div>
  
</template>

<script>
// import { mapGetters } from "vuex";
// import { api_base_url } from "@/core/config/app";

export default {
  name: "Brands",
  data() {
    return {
      brands: [],
    };
  },
  async mounted() {
    // this.brands.push({ logo: await import("../../assets/image/Brand1.png") });
  },
  computed: {
    // ...mapGetters(["getBrandListing"]),
  },
  methods: {
    showImage(e) {
      // return api_base_url + e;
    },
  },
};
</script>

<style scoped>
</style>