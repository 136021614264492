<template>
  <section id="requirements">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="requirements_img">
            <div class="requirements_img_text">
              <h2>New in Stock Tools</h2>
              <!-- <p> Renovation for Solid Woods Since, 1994</p>
                            <h1>DEWALT
                                <br>
                                POWER DRILL
                            </h1>
                            <button>Shop Now</button> -->
            </div>
            <img
              class="w-100 img-fluid"
              :src="show_img(home_slides)"
              alt="img"
            />
          </div>

          <!-- <div class="requirements_btn">
                        <button class="col-md-2">super 64</button>
                        <button class="col-md-2">View All Products</button>
                        <button class="col-md-2">Popular Products</button>
                        <button class="col-md-2">Super Asia</button>
                    </div> -->
        </div>
        <div class="col-lg-4">
          <div class="requirements_form">
            <h2>
              tell us your
              <br />
              requirements
            </h2>
            <img :src="from_image" alt="" />
            <form
              @submit.prevent="onSubmit"
              @keydown="formReq.onKeydown($event)"
            >
              <input
                type="text"
                placeholder="Product / Search Name*"
                v-model="$v.formReq.product.$model"
              />
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Product / Service Details*"
                v-model="$v.formReq.details.$model"
              ></textarea>
              <button type="submit">
                  <span
                      v-if="formReq.busy"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                Submit Request</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import from_image from "../../assets/image/from.png";
import { api_base_url } from "@/core/config/app";
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  minLength,
  sameAs,
  email,
} from "vuelidate/lib/validators";
import Toaster from "v-toaster";
Vue.use(Toaster, { timeout: 5000 });
export default {
    mixins: [validationMixin],
    name: "Requirements",
    data() {
        return {
        image: "",
        from_image: from_image,
        formReq: new Form({
            product: "",
            details: "",
        }),
        };
    },
    methods: {
        show_img(url) {
        return api_base_url + JSON.parse(url.home_banner)[0];
        },
        onSubmit() {
        this.$v.formReq.$touch();
        
            console.log('values',this.$v.formReq.product.$model)
          this.formReq
            .post("user/requirements/add")
            .then(({ data }) => {
              this.formReq.reset();
              this.$toaster.success('Your request has been sent!', {timeout: 8000})
            //   this.formVerify.fill(data);
            //   this.openVerificationModal();
            })
            .catch((error) => {
              let data = error.response;
              if (data.status === 500) {
                $("#reg").modal("hide");
                swal.fire(
                  this.$t("message.common.error"),
                  this.$t("message.common.something_wrong"),
                  "warning"
                );
              }
            });
        },
    },
    validations: {
        formReq: {
            product: {
                required,
                maxLength: maxLength(255),
            },
            details: {
                required,
            }
        }
    },
    async created() {
        this.image = (await import("../../assets/image/machine_img.jpg")).default;
        // this.from_image=(await import('../../assets/image/from.png')).default
    },
    computed: {
        ...mapState({
        home_slides: (state) => state.homeslider.homeBanner,
        }),
    },
};
</script>