<template>
    <section class="ecommerce_part2 mt-4">
        <div class="container">
            <div class="deals_heading row">
                <div class="heading_left col-md-11">
                    <h2 class="title"> <strong>TOP SELLERS</strong></h2>
                </div>
                <div class="col-md-1 mt-4">
                    <a class="link-success" id="view_all"> View All</a>
                </div>
            </div>
            <div class="row">

                <div class="col-md-2 col-sm-3" v-for="item in top_sellers" :key="item.image1">
                    <div class="product-grid">
                        <div class="product-image">
                            <a href="#" class="image">
                                <img class="pic-1" :src="item.image1">
                                <img class="pic-2" :src="item.image2">
                            </a>
                            <span class="product-sale-label">-26%</span>

                        </div>
                        <div class="product-content">
                            <h3 class="title title_product"><a href="#">{{item.name}}</a></h3>
                            <div class="progress">
                                <div class="progress-bar bg-success" role="progressbar" style="width: 60%"
                                    aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <div class="d-flex justify-content-between mt-2">
                                <div>
                                    <span>Sold : 20</span>
                                </div>
                                <div>
                                    <span class="text-primary">Available : 10</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name:"TopSellers",
    data(){
        return{
            top_sellers:[]
        }
    },
    async mounted(){
        // this.top_sellers.push({image1:(await import('../../assets/image/1.jpg')).default,image2:(await import('../../assets/image/2.jpg')).default})
    }
}
</script>