<template>
  <section class="ecommerce_part2 mt-4">
    <div class="container">
      <div class="deals_heading row">
        <div class="heading_left col-md-11">
          <h2 class="title"><strong>TOP TRENDING PRODUCTS</strong></h2>
        </div>
        <div class="col-md-1 mt-4">
          <a class="link-success" id="view_all"> View All</a>
        </div>
      </div>
      <div class="row" >
        <div v-for="(item,idx) in topTrendingProducts"
          :key="idx"
          class="col-md-2 col-sm-3"
        >
          <div class="product-grid">
            <div class="product-image">
              <router-link :to="{name: 'single.product', params: {slug:item.slug}}" class="image">
                <img class="pic-1" :src="'https://api.e-littlemart.com/public/'+item.featured_img" />
                <!-- <img class="pic-2" :src="'https://api.ezontro.com/'+item.image2" /> -->
              </router-link>
              <!-- <span class="product-sale-label">-26%</span>
              <span class="product-sale-label" v-if="item.discount_type=='flat'">{{product.discount}}</span>
              <span class="product-sale-label" v-else>{{product.discount}}</span> -->
            </div>
            <div class="product-content mt-4">
              <h2 class="title title_product">
                <router-link style="font-size:15px !important;" :to="{name: 'single.product', params: {slug:item.slug}}">{{item.name}}</router-link>
              </h2>
              <div class="progress">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  style="width: 60%"
                  aria-valuenow="30"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="d-flex justify-content-between mt-2">
                <div>
                  <span>Sold : {{item.num_of_sale}}</span>
                </div>
                <div>
                  <span class="text-primary">Available : {{item.quantity}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { TOP_TRENDING_PRODUCT } from "@/core/services/store/module/category";
export default {
  name: "TopTrending",
  data() {
    return {
    };
  },
  created(){
       this.$store.dispatch(TOP_TRENDING_PRODUCT);
  },
   computed: {
    ...mapGetters(["topTrendingProducts"]),
  },
};
</script>