<template>
  <div>
    <section class="ecommerce_part2 mt-4">
      <div class="container">
        <div class="deals_heading row">
          <div class="heading_left col-md-11">
            <h2 class="title">
              <strong>CHOOSE YOUR SUPPLIERS BY REGIONS</strong>
            </h2>
          </div>
          <div class="col-md-1 mt-4">
            <a class="link-success" id="view_all"> View All</a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-1 col-sm-3" v-for="country in countryList.slice(0,10)" :key="country.id">
            <div class="product-grid">
              <div class="product-content">
                <div class="d-flex justify-content-between mt-2">
                  {{ country.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="home-newsletter">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="single">
              <p>
                Trade Alert Delivering the latest product trends and industry
                news straight to your inbox.
              </p>
              <div class="input-group">
                <!-- <form @submit.prevent="submit" @keydown="form.onKeydown($event)"> -->
                <input
                  type="email"
                  name="email"
                  class="form-control"
                  placeholder="Enter your email"
                  v-model="form.email"
                />
                <span class="input-group-btn">
                  <button class="btn btn-theme" type="button" :click="submit">
                    Subscribe
                  </button>
                </span>
                <has-error :form="form" field="email"></has-error>
                <!-- </form> -->
              </div>
              <p class="mt-4">
                We.ll never share your email address with a third party.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { COUNTRY_LIST } from "@/core/services/store/module/country";
import { mapGetters } from 'vuex';
export default {
  name: "Newsletter",
  data() {
    return {
      form: new Form({
        email: "",
      }),
      // regions: [],
    };
  },
  created() {
    this.$store.dispatch(COUNTRY_LIST);
  },
  computed: {
    ...mapGetters(["countryList"])
  },
  methods: {
    submit() {
      this.form.post("subscribe").then(() => {
        this.form.reset();
        toast.fire({
          icon: "success",
          title: this.$t("message.new_letter.subscribe_successfully"),
        });
      });
    },
  },
};
</script>

<style scoped>
.newsletter {
}

.newsletter .content {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
}

.newsletter .content h2 {
  color: #243c4f;
  margin-bottom: 40px;
}

.newsletter .content .form-control {
  height: 50px;
  border-color: #243c4f;
  border-radius: 0;
}

.newsletter .content.form-control:focus {
  box-shadow: none;
  border: 2px solid #243c4f;
}

.newsletter .content .btn {
  min-height: 50px;
  border-radius: 0;
  background: #243c4f;
  color: #fff;
  font-weight: 600;
}
</style>