<template>
     <section class="promotion">
        <div class="container">
            <div class="row">
                <div v-for="item in promotions" :key="item.image1" class="col-md-4 bootm-offer">
                    <img :src="item.image1" alt="">
                </div>

            </div>
        </div>
    </section>
</template>
<script>
export default {
    name:"Promotion",
    props:['data'],
    data(){
        return{
            promotions:[]
        }
    },
    async mounted(){
        this.promotions.push({image1:(await import('../../assets/image/of-1.png')).default})
        this.promotions.push({image1:(await import('../../assets/image/of-2.png')).default})
        this.promotions.push({image1:(await import('../../assets/image/of-3.png')).default})
    }
}
</script>