<template>
     <section class="promotion">
        <div class="container">
            <div class="row">
                <div class="col-md-4 bootm-offer">
                    <img :src="promotions[0]" alt="">
                </div>
                <div class="col-md-4 bootm-offer">
                    <img :src="promotions[1]" alt="">
                </div>
                <div class="col-md-4 bootm-offer">
                    <img :src="promotions[2]" alt="">
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name:"Promotion",
    data(){
        return{
            promotions:[]
        }
    },
    async mounted(){
        this.promotions.push((await import('../../assets/image/of-1.png')).default)
        this.promotions.push((await import('../../assets/image/of-2.png')).default)
        this.promotions.push((await import('../../assets/image/of-3.png')).default)
    }
}
</script>