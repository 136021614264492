<template>
  <section id="deals">
    <div class="container best_deals">
      <div class="deals_heading row">
        <div class="heading_left col-md-11">
          <h2 class="title"><strong>POPULAR CATEGORIES</strong></h2>
        </div>
        <div class="col-md-1 mt-4">
          <a class="link-success" id="view_all">View All</a>
        </div>
      </div>
      <div class="row">
        <div class="container">
          <div class="row">
            <div
              class="col-md-2 col-sm-3"
              v-for="item in categoryList.slice(0, 4)"
              :key="item.id"
            >
              <div class="product-grid">
                <div class="product-image">
                  <a href="#" class="image">
                    <img class="pic-1" :src="showImage(item.banner)" />
                  </a>
                </div>
                <div class="product-content">
                  <h3 class="title_product">
                    <a href="#">{{item.name}}</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--end-->
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { CATEGORY_LIST } from "@/core/services/store/module/category";
import { api_base_url } from '../../core/config/app';
export default {
  name: "PopularCategories",
  computed: {
    ...mapGetters(["categoryList"]),
  },
  created() {
    // this.$store.dispatch(CATEGORY_LIST);
  },
  methods:{
    showImage(e) {
      return api_base_url + e;
    },
  }
  
};
</script>